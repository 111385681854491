<template>
  <div>
    <div>Страница цветов</div>
  </div>
</template>

<script>
export default {
  name: "ColorsPage"
};
</script>

<style scoped lang="scss"></style>
